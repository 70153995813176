<template>
  <div>
    <h3 class="bold d-flex justify-content-center mb-3">
      Офис: {{ office.office_name }}
    </h3>

    <div class="offices">
      <!----------------------------------------Администраторы------------------------------------------------------->
      <div class="offices-block">
        <div class="d-flex justify-content-between mb-4 bold">
          <span>Администраторы</span>
          <span
            class="edit-block fake-link c-pointer"
            @click="isShowUsersForAdmin = !isShowUsersForAdmin"
            >{{ isShowUsersForAdmin ? "Скрыть" : "Редактировать" }}</span
          >
        </div>
        <ul>
          <li v-for="item in getAdmins" :key="item.id" class="users-list mb-2">
            {{ item.text }}
            <span
              v-if="isShowUsersForAdmin"
              class="bold fake-link"
              @click="deleteAccess(item.id, 'office_admin')"
              >[Удалить]</span
            >
          </li>
        </ul>

        <transition name="fade" :duration="{ enter: 250, leave: 20 }">
          <div v-if="isShowUsersForAdmin">
            <span class="d-inline-block bold mt-2 mb-4"
              >Выберите пользователя, которого вы хотите назначить
              администратором:
            </span>
            <ul>
              <li
                v-for="user in availableUsersForAdmin"
                :key="user.id"
                class="mb-2"
              >
                {{ user.text }}
                <span
                  class="bold fake-link"
                  @click="addUserToAcl(user.id, 'office_admin')"
                  >[Добавить]</span
                >
              </li>
            </ul>
          </div>
        </transition>
      </div>

      <!----------------------------------------Менеджеры------------------------------------------------------->
      <div class="offices-block">
        <div class="d-flex justify-content-between mb-4 bold">
          <span>Менеджеры</span>
          <span
            class="edit-block fake-link c-pointer"
            @click="isShowUsersForManager = !isShowUsersForManager"
            >{{ isShowUsersForManager ? "Скрыть" : "Редактировать" }}</span
          >
        </div>
        <ul>
          <li
            v-for="item in getManagers"
            :key="item.id"
            class="users-list mb-2"
          >
            {{ item.text }}
            <span
              v-if="isShowUsersForManager"
              class="bold fake-link"
              @click="deleteAccess(item.id, 'office_manager')"
              >[Удалить]</span
            >
          </li>
        </ul>

        <transition name="fade" :duration="{ enter: 250, leave: 20 }">
          <div v-if="isShowUsersForManager">
            <span class="d-inline-block bold mt-2 mb-4"
              >Выберите пользователя, которого вы хотите назначить менеджером:
            </span>
            <ul>
              <li
                v-for="user in availableUsersForOfficeManager"
                :key="user.id"
                class="mb-2"
              >
                {{ user.text }}
                <span
                  class="fake-link bold"
                  @click="addUserToAcl(user.id, 'office_manager')"
                  >[Добавить]</span
                >
              </li>
            </ul>
          </div>
        </transition>
      </div>
    </div>
    <!------------------------------------------------------------------------------------------------------------->

    <button type="button" @click="saveOffice" class="btn btn-second mb-3 mt-3">
      Сохранить
    </button>

    <hr />
  </div>
</template>

<script>
import RequestManager from "../function/request/RequestManager";

export default {
  props: {
    office: undefined,
    employees: undefined
  },
  data() {
    return {
      users: [],
      acl: {},
      isShowUsersForAdmin: false,
      isShowUsersForManager: false
    };
  },
  computed: {
    getAdmins() {
      return this.filteredAcl(this.acl, "office_admin");
    },
    getManagers() {
      return this.filteredAcl(this.acl, "office_manager");
    },
    availableUsersForAdmin() {
      return this.diffBy(this.users, this.getAdmins, "id");
    },
    availableUsersForOfficeManager() {
      return this.diffBy(this.users, this.getManagers, "id");
    }
  },
  methods: {
    diffBy(array1, array2, value) {
      return array1.filter(a => !array2.map(b => b[value]).includes(a[value]));
    },
    addUserToAcl(userId, role) {
      if (this.acl[userId] && this.acl[userId].length) {
        this.acl[userId].push(role);
      } else {
        this.$set(this.acl, userId, [role]);
      }
    },
    deleteAccess(userId, role) {
      this.acl[userId] = this.acl[userId].filter(el => el !== role);
    },
    filteredAcl(acl, role) {
      let users = [];
      if (acl) {
        for (let key in acl) {
          const searched = this.users.filter(el => el.id === parseInt(key));
          if (searched && searched.length) {
            if (acl[key].includes(role)) users.push(searched[0]);
          }
        }
      }
      return users;
    },
    officeAcl() {
      RequestManager()
        .getAcl("companies", "companyoffice", this.office.id)
        .then(res => {
          this.acl = res;
        });
    },
    saveOffice() {
      RequestManager()
        .putAcl("companies", "companyoffice", this.office.id, this.acl)
        .then(() => {
          this.$bvToast.toast("Изменения успешно сохранены", {
            variant: "success",
            title: "Успешно"
          });
        });
    }
  },
  created() {
    this.officeAcl();
    this.users = [...this.users, ...this.employees];
  }
};
</script>

<style scoped lang="scss">
.bold {
  font-weight: bold;
}
.offices {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 20px;
}
.fake-link {
  color: #006b90;
  cursor: pointer;
  margin-left: 5px;
}
.offices-block {
  padding: 20px;
  background-color: var(--main-card-color);
  border-radius: 5px;
  box-shadow: 0 5px 8px 0 rgba(34, 60, 80, 0.2);
}
.c-pointer {
  cursor: pointer;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>
